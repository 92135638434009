<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Software Versions</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Software
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn type="submit" @click="createNewSoftware" class="btn btn-primary"  style="color: #fff">
                    <v-icon small  elevation="2" outlined>fas fa-upload</v-icon>&nbsp;
                    Upload New Software
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-head-custom table-vertical-center"
                     id="kt_advance_table_widget_4">
                <thead>
                <tr class="text-left">
                  <th class="px-3 font-weight-bolder">Software Type</th>
                  <th class="px-3 font-weight-bolder">
                    <span class="text-primary">Release Name</span>
                  </th>
                  <th class="px-3 font-weight-bolder">Release Date</th>
                  <th class="px-3 font-weight-bolder">OS Type</th>
                  <th class="px-3 font-weight-bolder">File</th>
                   <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="item in softwares">
                    <td class="px-2">
                      <span class="font-weight-bolder d-block font-size-lg"
                      >{{item.software_type_text}}</span>
                    </td>
                    <td>
                      <span class="font-weight-bolder d-block font-size-lg"
                      >{{item.release_name}}</span>
                    </td>
                    <td>
                      <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {{item.release_date}}
                      </span>
                    </td>
                    <td>
                      <span class="d-block font-size-lg">
                        <i class="text-dark-75 font-weight-bolder"> {{item.os_type}} </i>  
                      </span>
                    </td>
                    <td>
                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">amebexams_m_6.1.2.7_cloud.pkg</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="deleteSoftware(item)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon>fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
        v-model="dialog"
        height="500px"
        max-width="800px">
      <v-card>
        <v-card-title>
          <span >Upload Software</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                    outlined
                    dense
                    v-model="software.software_type"
                    :items="software_types"
                    item-text="text"
                    item-value="value"
                    label="Software Type*"
                    required
                ></v-select>
                <span
                  class="text-danger"
                  v-if="$v.software.software_type.$error"
                  >
                  This information is required
                </span>
                <span class="is-invalid text-danger" v-if="errors.software_type">* {{errors.software_type[0]}}</span>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                    outlined
                    dense
                    label="Release Name *"
                    v-model="software.release_name"
                ></v-text-field>
                <span
                  class="text-danger"
                  v-if="$v.software.release_name.$error"
                  >
                  This information is required
                </span>
                <span class="is-invalid text-danger" v-if="errors.release_name">* {{errors.release_name[0]}}</span>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                    outlined
                    dense
                    type="date"
                    label="Release Date *"
                    v-model="software.release_date"
                ></v-text-field>
                <span
                  class="text-danger"
                  v-if="$v.software.release_date.$error"
                  >
                  This information is required
                </span>
                <span class="is-invalid text-danger" v-if="errors.release_date">* {{errors.release_date[0]}}</span>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                    outlined
                    dense
                    v-model="software.os_type"
                    :items="os_types"
                    item-text="text"
                    item-value="value"
                    label="OS Types*"
                ></v-select>
                <span
                  class="text-danger"
                  v-if="$v.software.os_type.$error"
                  >
                  This information is required
                </span>
                <span class="is-invalid text-danger" v-if="errors.os_type">* {{errors.os_type[0]}}</span>
              </v-col>
              <v-col cols="12">
                <v-file-input 
                  v-model="software.file"
                  outlined
                  dense
                  placeholder="Upload file"
                  label="File *" prepend-icon="mdi-paperclip"
                >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                </v-file-input>
                <span
                  class="text-danger"
                  v-if="$v.software.file.$error"
                  >
                  This information is required
                </span>
                <span class="is-invalid text-danger" v-if="errors.file">* {{errors.file[0]}}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="btn btn-primary"
              dark
              medium
              @click="saveSoftware"
          >
            Save
          </v-btn>
          <v-btn
              color="btn btn-standard"
              dark
              medium
              @click="closeDialog"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import SoftwareService from '@/services/cms/software/SoftwareService';
const software = new SoftwareService();


export default {
  validations:{
    software: {
      software_type: {required},
      release_name: {required},
      release_date: {},
      os_type: {required},
      file: {required},
    },
  },
  data() {
    return{
      dialog: false,
      page: null,
      total: null,
      perPage: null,
      search:{
        release_name: ''
      },
      softwares: [],
      software: {
        software_type: '',
        release_name: '',
        release_date: new Date().toISOString().substr(0, 10),
        os_type: '',
        file: null,
      },
      software_types: [],
      os_types: [],
      errors: [],
    }
  },
  methods: {
    closeDialog(){
      this.resetForm();
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
    },
    resetForm(){
      this.software.software_type = '';
      this.software.release_name = '',
      this.software.release_date = new Date().toISOString().substr(0, 10),
      this.software.os_type = '';
      this.software.file = null;
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.software) {
          if (key === "file" && this.software[key] != null && this.software[key] != undefined) {
              formData.append('file', this.software[key]);
          }
          else {
              if(this.software[key]){
                  formData.append(key,this.software[key]);
              }
          }
      }
      return formData;
    },
    saveSoftware(){
      this.$v.$touch()
      if(this.$v.$error){
          setTimeout(() => {
          this.$v.$reset()
          }, 3000)
      }
      else {
        this.errors = [];
        let formData = this.convertToFormData();
        software
          .create(formData)
          .then((response) => {
            this.$snotify.success("Software Uploaded successfully");
            this.errors = [];
            this.closeDialog();
            this.getAllSoftwares();            
          })
          .catch((error) => {
            this.errors = error.response.data.errors; 
            this.$snotify.error("Oops something went wrong");
          });
      }
    },
    deleteSoftware(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            software
              .delete(item.id)
              .then((response) => {
                this.getAllSoftwares();
                this.$snotify.success("Software deleted");
              })
              .catch((err) => {

                this.$snotify.error("Oops something went wrong");
              });
            }
        }
      });
    },
    getSoftwareOptions(){
      software
       .getSoftwareOptions()
       .then((response) => {
          for(let key in response.data.softwareTypes){
            this.software_types.push(
              {
                'value' : key, 
                'text': response.data.softwareTypes[key]
              })
          }
          for(let key in response.data.osTypes){
            this.os_types.push(
              {
                'value' : key, 
                'text': response.data.osTypes[key]
              })
          }
       }) 
    },
    createNewSoftware(){
      this.openDialog();
    },
    getAllSoftwares(){
      software
      .paginate(this.search, this.page)
      .then(response => {
        this.softwares = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      })
      .catch((err) => {

      });
    }
  },
  mounted(){
    this.getAllSoftwares();
    this.getSoftwareOptions();
  }
}
</script>
